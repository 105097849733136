// A file to quickly throw state you want to use elsewhere
import create from 'zustand'

interface AdminLogs {
  logs: string[]
  addLog: (_log: string) => void
  clearLogs: () => void
}

export const useAdminLogs = create<AdminLogs>((set) => ({
  logs: [],
  addLog: (log: string) => set((state) => ({ logs: [...state.logs, log] })),
  clearLogs: () => set(() => ({ logs: [] }))
}))

export const useKbarFeedback = create<{
  feedbackModal: boolean
  toggleFeedbackModal: () => void
  user: { name: string; email?: string } | null
  setUser: (_user: { name: string; email?: string }) => void
}>((set) => ({
  feedbackModal: false,
  toggleFeedbackModal: () =>
    set((state) => ({ ...state, feedbackModal: !state.feedbackModal })),
  user: null,
  setUser: (user) => set((state) => ({ ...state, user }))
}))
