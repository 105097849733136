import posthog from "posthog-js";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { setAdminValue } from "@editor/admin/adminStore";
import { ANNO_USER_ID } from "@editor/interfaces";
import { modeStore } from "@editor/state";
import { useKbarFeedback } from "@editor/state/quickState";
import {
  useAnnoLoginMutation,
  useCreateAnnotateUserMutation,
  useGetAnnotateUserQuery,
  useUpdateAnnotateUserMutation
} from "@graphql/schema/client";
import { useFingerPrint } from "../../util/useFingerprint";
const getCookieDomain = () => {
  const isAnnoDomain = window.location.host.includes("anno.so");
  return isAnnoDomain ? ".anno.so" : "";
};
export const useAnnotateUser = () => {
  var _a;
  const { fingerprint } = useFingerPrint();
  const [cookies, setCookie, removeCookie] = useCookies(["token", ANNO_USER_ID]);
  const setTokenCookie = (token) => {
    const expires = new Date(Date.now() + 1e3 * 60 * 60 * 24 * 60);
    setCookie("token", token, { path: "/", expires, domain: getCookieDomain() });
  };
  const removeTokenCookie = () => {
    removeCookie("token", { path: "/", domain: getCookieDomain() });
  };
  const removeUserIdCookie = () => {
    removeCookie(ANNO_USER_ID);
    removeCookie(ANNO_USER_ID, { path: "/" });
  };
  const setUser = useKbarFeedback((state) => state.setUser);
  useEffect(() => {
    setAdminValue("Auth.token", `${!!cookies.token}`);
    setAdminValue("Auth.annoUserId", `${!!cookies.annoUserId}`);
  }, [cookies.token, cookies.annoUserId]);
  const {
    data: user,
    loading,
    refetch: getAnnotateUser
  } = useGetAnnotateUserQuery({
    onCompleted: (data) => {
      var _a2;
      console.log("getAnnotateUser", data.getAnnotateUser);
      if (!data.getAnnotateUser)
        return removeTokenCookie();
      setUser({
        name: data.getAnnotateUser.name,
        email: data.getAnnotateUser.email || void 0
      });
      setAdminValue("Auth.email", data.getAnnotateUser.email);
      if ((_a2 = data.getAnnotateUser.email) == null ? void 0 : _a2.endsWith("@modfy.video")) {
        modeStore.setDevConsole(true);
      }
    },
    onError: (err) => {
      console.log("getAnnotateUser failed", err);
    }
  });
  const [annoLogin] = useAnnoLoginMutation({
    onCompleted: async (data) => {
      console.log("annoLogin", data.annoLogin);
      if (data.annoLogin.__typename === "AnnoLoginSuccess") {
        const { token } = data.annoLogin;
        setTokenCookie(token);
        await getAnnotateUser();
      }
    },
    onError: (err) => {
      console.error("annoLogin", err);
    }
  });
  const [createAnnotateUser, { loading: createLoading }] = useCreateAnnotateUserMutation({
    onCompleted: async (data) => {
      if (data.createAnnotateUser.__typename === "AnnoUserOutput") {
        toast.success("Added User");
        const { token } = data.createAnnotateUser;
        if (token) {
          setTokenCookie(token);
          await getAnnotateUser();
        }
        posthog.capture("signup");
        posthog.identify(userId, data.createAnnotateUser);
      }
    },
    onError: (e) => {
      console.log("createAnnotateUser error", e);
    }
  });
  const [updateUser, { loading: updateUserLoading }] = useUpdateAnnotateUserMutation({
    onCompleted: async (data) => {
      var _a2, _b;
      console.log("updateAnnotateUser result", data.updateAnnotateUser);
      if (data.updateAnnotateUser.__typename === "AnnoUserOutput") {
        const { user: updatedUser, token } = data.updateAnnotateUser;
        if (token) {
          setTokenCookie(token);
          await getAnnotateUser();
        }
        if (updatedUser.id !== ((_a2 = user == null ? void 0 : user.getAnnotateUser) == null ? void 0 : _a2.id)) {
          posthog.alias(updatedUser.id, (_b = user == null ? void 0 : user.getAnnotateUser) == null ? void 0 : _b.id);
        }
      }
    },
    onError: (err) => {
      console.log("updateAnnotateUser failed", err);
    }
  });
  const userId = (_a = user == null ? void 0 : user.getAnnotateUser) == null ? void 0 : _a.id;
  return {
    annoLogin,
    userId,
    user,
    createAnnotateUser,
    updateUser,
    updateUserLoading,
    loading,
    createLoading,
    fingerprint,
    getAnnotateUser,
    removeUserIdCookie,
    setTokenCookie,
    removeTokenCookie
  };
};
export const useLegacyAnnotateUser = () => {
  const { fingerprint, annoLogin, removeUserIdCookie } = useAnnotateUser();
  const [cookies] = useCookies(["token", ANNO_USER_ID]);
  useEffect(() => {
    if (!cookies.token && (cookies.annoUserId || fingerprint)) {
      console.log("Logging in legacy user", {
        id: cookies.annoUserId,
        fingerprint
      });
      annoLogin({
        variables: { input: { id: cookies.annoUserId, fingerprint } }
      }).then(({ data }) => {
        if ((data == null ? void 0 : data.annoLogin.__typename) === "AnnoLoginSuccess") {
          removeUserIdCookie();
        }
      }).catch(console.error);
    }
  }, [annoLogin, cookies.annoUserId, cookies.token, fingerprint]);
};
