import { useEffect, useState } from "react";
export const useFingerPrint = () => {
  const [fingerprint, setFingerPrint] = useState(void 0);
  useEffect(() => {
    const asyncFunc = async () => {
      const fp = await import("./fingerprint");
      const fingerprint2 = await fp.getUserFingerprint();
      if (fingerprint2) {
        setFingerPrint(fingerprint2);
      }
    };
    asyncFunc();
  }, []);
  return { fingerprint };
};
