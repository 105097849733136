import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import { useAnnotateUser } from "../components/hooks/useAnnotateUser";
import { createAnnoMagic } from "../util/magic";
export const LOGIN_FINISH = "login_finish";
const relevantQueryParams = () => {
  const url = new URL(window.location.href);
  return [
    url.searchParams.get("provider"),
    url.searchParams.get("magic_credential")
  ];
};
const LoginCallback = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [magic, setMagic] = useState(null);
  const [success, setSuccess] = useState(false);
  const { user, fingerprint, annoLogin, loading, updateUser } = useAnnotateUser();
  const router = useRouter();
  useEffect(() => {
    setMagic(createAnnoMagic());
  }, []);
  useEffect(() => {
    console.log("useEffect", { fingerprint, magic, loading });
    if (!fingerprint || !magic || loading)
      return;
    const [provider, magicCredentials] = relevantQueryParams();
    console.log("relevantQueryParams", { provider, magicCredentials });
    if (provider)
      finishSocialLogin().catch(console.error);
    else
      finishEmailRedirectLogin(magicCredentials).catch(console.error);
  }, [magic, fingerprint, loading]);
  const sendEventAndClose = async (token) => {
    setSuccess(true);
    if (window.opener) {
      const id = router.query.id;
      console.log(`Emitting event ${LOGIN_FINISH}_${id}`);
      const customEvent = new CustomEvent(`${LOGIN_FINISH}_${id}`, {
        detail: { token }
      });
      window.opener.dispatchEvent(customEvent);
    }
    await new Promise((resolve) => setTimeout(resolve, 1e3));
    window.close();
  };
  const finishLogin = async (idToken) => {
    if (user == null ? void 0 : user.getAnnotateUser) {
      const { data } = await updateUser({
        variables: { updateUser: { didToken: idToken } }
      });
      console.log("Update user", data);
      if ((data == null ? void 0 : data.updateAnnotateUser.__typename) === "AnnoUserOutput") {
        return sendEventAndClose(data.updateAnnotateUser.token);
      }
      throw new Error("Couldnt update user");
    } else {
      const { data } = await annoLogin({
        variables: { input: { didToken: idToken, fingerprint } }
      });
      if ((data == null ? void 0 : data.annoLogin.__typename) === "AnnoLoginSuccess") {
        return sendEventAndClose(data.annoLogin.token);
      }
      throw new Error("Couldnt login user");
    }
  };
  const finishSocialLogin = async () => {
    console.log("Doing social login");
    try {
      if (!magic)
        throw new Error("Magic Undefined");
      const {
        magic: { idToken }
      } = await magic.oauth.getRedirectResult();
      finishLogin(idToken);
    } catch (error) {
      console.log(error);
      setErrorMsg("Error logging in. Please try again.");
    }
  };
  const finishEmailRedirectLogin = async (magicCredential) => {
    console.log("Doing email redirect login");
    if (!magicCredential) {
      console.log("No magic credentials");
      setErrorMsg("Error logging in. Please try again.");
      return;
    }
    try {
      if (!magic)
        throw new Error("Magic Undefined");
      const idToken = await magic.auth.loginWithCredential();
      if (!idToken)
        throw new Error("Failed to login");
      finishLogin(idToken);
    } catch (error) {
      console.log("Error:", error);
      setErrorMsg("Error logging in. Please try again.");
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "bg-blueGray-900 min-w-full min-h-screen flex items-center justify-center"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center space-x-4"
  }, success ? /* @__PURE__ */ React.createElement(CheckCircledIcon, {
    className: "h-8 w-8 text-indigo-500"
  }) : errorMsg ? /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    className: "h-8 w-8 text-red-500",
    fill: "none",
    viewBox: "0 0 24 24",
    stroke: "currentColor"
  }, /* @__PURE__ */ React.createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
    d: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
  })) : /* @__PURE__ */ React.createElement("svg", {
    className: "animate-spin h-8 w-8 text-indigo-500",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24"
  }, /* @__PURE__ */ React.createElement("circle", {
    className: "opacity-25",
    cx: "12",
    cy: "12",
    r: "10",
    stroke: "currentColor",
    strokeWidth: "4"
  }), /* @__PURE__ */ React.createElement("path", {
    className: "opacity-75",
    fill: "currentColor",
    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
  })), success ? /* @__PURE__ */ React.createElement("p", {
    className: "text-indigo-500 text-4xl"
  }, "Successfully logged in") : errorMsg ? /* @__PURE__ */ React.createElement("p", {
    className: "text-red-500 text-4xl"
  }, errorMsg) : /* @__PURE__ */ React.createElement("p", {
    className: "text-indigo-500 text-4xl"
  }, "Logging in ...")));
};
export default LoginCallback;
